.sl-btn {
  width: 200px;
  height: 50px;
  border-radius: 30px;
  background: #8c46fe;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Assistant;
  font-size: 18px;
  font-weight: 800;
  line-height: 18px;
  margin-top: 20px;
}
